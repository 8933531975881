/* 컨테이너 스타일 */
.partner-detail-container {
    max-width: 1024px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
}

/* 헤더 스타일 */
.partner-detail-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    position: relative;
}

.partner-detail-title {
    font-size: 30px;
    color: #4c00b4;
    font-weight: bold;
    text-align: center;
    flex-grow: 1;
}

.back-icon {
    position: absolute;
    left: 0; /* 아이콘을 왼쪽에 고정 */
    font-size: 24px;
    cursor: pointer;
    color: #333;
}

.back-icon:hover {
    color: #0056b3;
}

/* 파트너 이름 헤더 스타일 */
.partner-name-header {
    margin-bottom: 20px;
}

.partner-name {
    font-size: 2rem;
    margin-top: 0;
    font-weight: bold;
}

/* 정보 카드 스타일 */
.partner-detail-card {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    margin-bottom: 20px;
}

.partner-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.partner-info-row {
    background-color: #f1f1f1;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.partner-info-row span {
    color: #333;
    font-weight: bold;
}

/* 하위 제목 스타일 */
.partner-detail-subtitle {
    font-size: 20px;
    margin: 20px 0 10px;
    color: #333;
    border-bottom: 2px solid #007bff;
    padding-bottom: 5px;
}

/* 주문 폼 스타일 */
.partner-detail-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.partner-detail-form h2 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
}

.partner-detail-form input {
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    color: #333;
}

.partner-detail-form input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.partner-detail-form button {
    padding: 12px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    transition: background-color 0.3s ease;
}

.partner-detail-form button:hover {
    background-color: #218838;
}

.partner-detail-form select {
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 150px; /* 또는 원하는 너비로 조정 */
}

.partner-detail-form select:focus {
    outline: none;
    border-color: #007bff;
}

.partner-info-row.full-width {
    grid-column: 1 / -1; /* 전체 너비 사용 */
  }
  
  .partner-info-row.full-width span:last-child {
    word-break: break-all; /* 긴 텍스트 줄바꿈 */
  }

/* 기존 스타일은 그대로 유지 */

/* 모바일 대응 미디어 쿼리 */
@media (max-width: 768px) {
    /* 컨테이너 스타일 */
    .partner-detail-container {
        margin: 20px auto;
        padding: 15px;
    }

    /* 헤더 스타일 */
    .partner-detail-header {
        align-items: flex-start;
    }

    .partner-detail-title {
        font-size: 24px;
        text-align: center;
        margin-bottom: 10px;
    }

    .back-icon {
        font-size: 20px;
    }

    /* 파트너 이름 헤더 스타일 */
    .partner-name-header {
        margin-bottom: 10px;
    }

    .partner-name {
        font-size: 1.5rem;
    }

    /* 정보 카드 스타일 */
    .partner-detail-card {
        padding: 10px;
    }

    .partner-info {
        grid-template-columns: 1fr; /* 단일 열로 변경 */
        gap: 5px;
    }

    .partner-info-row {
        padding: 8px;
        font-size: 14px;
    }

    /* 하위 제목 스타일 */
    .partner-detail-subtitle {
        font-size: 18px;
        margin: 15px 0 5px;
        padding-bottom: 3px;
    }

    /* 주문 폼 스타일 */
    .partner-detail-form {
        padding: 15px;
        gap: 10px;
    }

    .partner-detail-form input {
        padding: 10px;
        font-size: 14px;
    }

    .partner-detail-form button {
        padding: 10px;
        font-size: 14px;
    }
}

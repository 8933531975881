.partner-list-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 1024px;
    margin: 0 auto;
    margin-top: 40px;
}

.partner-list-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #4c00b4;
    margin-bottom: 20px;
}

.partner-list-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.partner-list-table th, .partner-list-table td {
    padding: 12px 15px;
    text-align: center;
    border-bottom: 1px solid #ddd;
}

.partner-list-table th {
    background-color: #4c00b4;
    color: #ffffff;
    font-weight: bold;
}

.partner-list-table tr:hover {
    background-color: #f1f1f1;
}

.partner-list-table td {
    color: #333;
}

.partner-list-table td.right-align {
    text-align: right;
}

.partner-list-table .total-row {
    background-color: #f0f0ff; /* 연한 보라색 배경 */
    font-weight: bold;
    border-top: 2px solid #4c00b4; /* 상단에 진한 보라색 테두리 */
    border-bottom: 2px solid #4c00b4; /* 하단에 진한 보라색 테두리 */
}

.partner-list-table .total-row td {
    color: #4c00b4; /* 텍스트 색상을 진한 보라색으로 변경 */
}

@media (max-width: 768px) {
    .partner-list-container {
        padding: 10px;
    }

    .partner-list-table th, .partner-list-table td {
        padding: 10px;
        font-size: 14px;
    }

    .admin-title {
        font-size: 20px;
    }
}

.partner-card-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.partner-card {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.partner-card h3 {
    margin-bottom: 10px;
    color: #4c00b4;
}

.partner-card p {
    margin: 5px 0;
}

.partner-card-list .total-card {
    background-color: #f0f8ff; /* 연한 파란색 배경 */
    border: 2px solid #4682b4; /* 진한 파란색 테두리 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 그림자 효과 */
    padding: 20px;
    margin-bottom: 20px; /* 다른 카드와의 간격 */
    border-radius: 8px; /* 모서리 둥글게 */
  }
  
  .partner-card-list .total-card h3 {
    color: #4682b4; /* 제목 색상 */
    font-size: 1.5em; /* 제목 크기 */
    margin-bottom: 15px;
  }
  
  .partner-card-list .total-card p {
    font-weight: bold; /* 글씨 굵게 */
    margin: 10px 0;
  }
  

@media (max-width: 768px) {
    .partner-list-container {
        padding: 10px;
    }

    .partner-list-title {
        font-size: 20px;
    }

    .partner-card {
        font-size: 14px;
    }
}

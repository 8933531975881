.user-details-container {
  max-width: 1024px;
  margin: 50px auto 0 auto; /* 상단 마진 50px, 좌우 자동 정렬 */
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.user-details-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #4c00b4;
    margin-bottom: 20px;
}

.user-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.user-name {
  font-size: 2.5rem;
  color: #2c3e50;
  margin: 0;
}

.user-avatar {
  width: 80px;
  height: 80px;
  background-color: #3498db;
  color: white;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.user-info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.info-item {
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  /*transition: all 0.3s ease;*/
}

.info-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.info-label {
  font-weight: bold;
  color: #34495e;
  margin-right: 0.5rem;
}

.info-value {
  color: #7f8c8d;
}

.referral-code {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.copy-icon {
  width: 30px;  /* 아이콘 크기를 조절하세요 */
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.copy-icon:hover {
  opacity: 0.7;
}

.copy-feedback {
  margin-left: 10px;
  color: #27ae60;
  font-size: 0.9rem;
}


.api-keys-section, .wallet-address-section {
  margin-top: 2rem;
}

.api-keys-section h2, .wallet-address-section h2 {
  font-size: 1.8rem;
  color: #2c3e50;
  margin-bottom: 1rem;
  border-bottom: 2px solid #3498db;
  padding-bottom: 0.5rem;
}

.api-keys-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

.api-key-item {
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.api-key-item h3 {
  font-size: 1.4rem;
  color: #2c3e50;
  margin-bottom: 0.8rem;
}

@media (max-width: 600px) {
  .user-info-grid, .api-keys-grid {
    grid-template-columns: 1fr;
  }

  .user-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .user-avatar {
    margin-top: 1rem;
  }
}
.terms-content {
    padding: 20px;
    background-color: #f9f9f9;
    /* border-radius: 8px; */
    max-width: 1024px;
    margin: 0 auto;
    /* margin-top: 40px; */
    height: 90vh;
    overflow-x: hidden;
}

.terms-of-service {
    margin: 10px;
    padding: 10px;
}

ol {
    margin-left: 10px;
}


li {
    margin: 10px;
}

.terms-of-service h2 {
    margin-top: 10px;
    margin-bottom: 5px;
}

.terms-of-service h3 {
    margin-top: 5px;
}

.page-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-left: 20px;
}

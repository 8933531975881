/* Loading.css */
.loading-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.loading-container.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.loading-overlay {
  /* position: absolute; fixed에서 absolute로 변경 */
  width: 100%;
  height: 100%; /* 높이를 auto로 변경하여 상위 태그에 맞춤 */
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-text {
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-align: center; /* 텍스트를 중앙 정렬 */
  padding: 20px; /* 패딩 추가 */
}

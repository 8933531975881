/* OrderList.css */

.order-list-container {
    
    border: 1px solid #ddd; /* 스크롤 영역을 시각적으로 구분하기 위한 테두리 추가 */
    padding: 10px; /* 내부 여백 설정 */
}

.order-list-title {
    font-size: 24px;
    margin-bottom: 15px;
    color: #333;
    border-bottom: 2px solid #007bff;
    padding-bottom: 5px;
}

.order-list-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.order-list-table th,
.order-list-table td {
    padding: 10px 0;
    padding: 10px 0;
    text-align: center;
    border-bottom: 1px solid #ddd;
}

.order-list-table th {
    background-color: #f1f1f1;
    text-align: center;
    font-weight: bold;
}

.order-list-table tr:hover {
    background-color: #f9f9f9;
}

.detail-button {
    background-color: #4c00b4;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.detail-button:hover {
    background-color: #3c009d;
}

a {
    text-decoration: none;
}
.container {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 200px;
    width: 600px;
    background: #fff;
    padding-bottom: 30px;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    width: 100%;
    margin-top: 30px ;
}

.text {
    color: #3c009d;
    font-size: 48px;
    font-weight: 700;
}

.underline {
    width: 61px;
    height: 6px;
    background: #3c009d;
    border-radius: 9px;
}

.inputs {
    margin-top: 55px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.input {
    display: flex;
    align-items: center;
    margin: auto;
    width: 480px;
    height: 80px;
    background: #eaeaea;
    border-radius: 6px;
}

.input img {
    margin: 0px 30px;
}

.input input {
    height: 50px;
    width: 400px;
    background: transparent;
    border: none;
    outline: none;
    color: #797979;
    font-size: 19px;
}

.forgot-password {
    padding-left: 62px;
    margin-top: 27px;
    color: #797979;
    font-size: 18px;
}

.forgot-password span {
    color: #4c00b4;
    cursor: pointer;
}

.click-link {
    display: flex;
    justify-content: space-between;
    padding: 0 62px;
    margin-top: 27px;
    color: #797979;
    font-size: 18px;
}

.click-link span {
    color: #4c00b4;
    cursor: pointer;
}

.submit-container {
    display: flex;
    flex-direction: column;
    margin: 60px;
}

.submit {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 480px;
    height: 59px;
    color: #fff;
    background: #4c00b4;
    border-radius: 50px;
    font-size: 19px;
    font-weight: 700;
    cursor: pointer;
}

.gray {
    background: #eaeaea;
    color: #676767;
}

.navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.navigation-buttons button {
    margin: 0 auto;
    background-color: #4c00b4;
    color: #fff;
    width: 150px;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.navigation-buttons button:disabled {
    background-color: gray; /* 비활성화된 버튼의 배경색을 회색으로 설정 */
    cursor: not-allowed; /* 비활성화된 버튼은 클릭할 수 없음을 나타내는 커서 */
    color: #ccc; /* 비활성화된 버튼의 텍스트 색상을 연한 회색으로 설정 */
}

.navigation-buttons button:hover {
    background-color: #3c009d;
}

.error-message {
    padding-left: 30px;
    color: tomato;
    font-size: 18px;
}

/* 미디어 쿼리 추가: 작은 화면 대응 */
@media (max-width: 768px) {
    .container {
        width: 90%; /* 화면 크기에 따라 너비를 조정 */
        margin-top: 100px;
    }

    .input {
        width: 100%; /* 입력 필드 너비를 100%로 설정 */
        height: 60px;
    }

    .input img {
        margin: 0px 15px;
    }

    .input input {
        width: calc(100% - 40px); /* 전체 너비에서 이미지의 여백을 제외한 크기 */
        font-size: 16px;
    }

    .submit {
        width: 100%;
    }

    .text {
        font-size: 36px;
    }

    .forgot-password {
        padding-left: 20px;
        font-size: 16px;
    }
}

/* 미디어 쿼리 추가: 아주 작은 화면 대응 */
@media (max-width: 480px) {
    .container {
        width: 95%; /* 더 작은 화면에서 너비를 95%로 설정 */
        margin-top: 50px;
    }

    .input {
        height: 50px;
    }

    .input img {
        margin: 0px 10px;
    }

    .input input {
        font-size: 14px;
    }

    .submit {
        height: 50px;
        font-size: 16px;
    }

    .text {
        font-size: 28px;
    }

    .forgot-password {
        font-size: 14px;
    }
}

/* 탭 스타일 */
.tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px; /* 탭과 다른 요소 간의 간격 */
}

.tab {
    cursor: pointer;
    padding: 10px 20px;
    border: 1px solid transparent; /* 기본 테두리 */
    border-radius: 5px;
    transition: background-color 0.3s, border-color 0.3s;
    text-decoration: none;
}

.tab:hover {
    background-color: #f0f0f0; /* 호버 시 배경색 변경 */
    border-color: #ccc; /* 호버 시 테두리 색 변경 */
}

.tab.active {
    background-color: #4c00b4; /* 활성화된 탭 배경색 */
    color: white; /* 활성화된 탭 글자색 */
}

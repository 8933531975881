.content {
    overflow: auto;
    height: 500px;
}
.terms-of-service {
    width: 100%;
    margin: 10px;
    padding: 10px;
}

ol {
    margin-left: 10px;
}


li {
    margin: 10px;
}

.terms-of-service h2 {
    margin-top: 10px;
    margin-bottom: 5px;
}

.terms-of-service h3 {
    margin-top: 5px;
}

.page-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-left: 20px;
}
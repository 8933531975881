.order-card-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 0.75rem;
}

.order-card {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 0.5rem;
  padding: 0.75rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.order-card:hover {
  background-color: #e9ecef;
}

.order-card p {
  margin: 0.4rem 0;
  font-size: 0.8rem;
}

.order-card .order-date {
  font-weight: bold;
}

.order-card .order-amount {
  color: #28a745;
}

.order-card .order-status {
  text-transform: capitalize;
}

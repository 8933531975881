body {
    margin: 0;
    font-family: -apple-system, "Gill Sans", sans-serif, 'Ubuntu', 'sans-serif';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    /*background: linear-gradient(#2A00B7, #42006C);*/
    background: linear-gradient(#354649, #6C7A89);
}

code {
    font-family: "Source Code Pro", "Menlo", Monaco, Consolas, monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App {
  font-family: sans-serif;
  text-align: center;
}


/* 미디어 쿼리 추가: 작은 화면에서 폰트 크기 조정 */
@media (max-width: 768px) {
    body {
        font-size: 14px;
    }
}

/* 미디어 쿼리 추가: 아주 작은 화면에서 폰트 크기 더 작게 */
@media (max-width: 480px) {
    body {
        font-size: 12px;
    }
}

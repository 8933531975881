/* 공통 입력 필드 스타일 */
.input {
    height: 50px;
    margin-bottom: 15px;
    width: calc(100% - 40px); /* 아이콘 크기만큼 너비 계산 */
    display: flex;
    align-items: center;
}

.input input {
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
}

.input.with-icon {
    position: relative;
}

/* 붙여넣기 아이콘 스타일 */
.paste-icon {
    position: absolute;
    right: 0px; /* 아이콘이 입력 필드 안쪽에 위치하도록 설정 */
    cursor: pointer;
    width: 24px;
    height: 24px;
}

/* 요약 컨테이너 및 테이블 스타일 */
.summary-container {
    background-color: #f0f0f0;  /* 회색 배경 */
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.summary {
    padding: 10px;
    border-radius: 5px;
}

.summary p {
    margin: 10px 0;
    color: #333;
    font-size: 16px;
}

.summary-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.summary-table td {
    padding: 10px;
    border: 1px solid #ccc;
    vertical-align: top;
}

.key-column {
    width: 30%;
    background-color: #f9f9f9;
}

.value-column {
    width: 60%;
    background-color: #fff;
    word-break: break-all;
}

/* 제출 버튼 스타일 */
.submit-container {
    margin-top: 20px;
}

.submit {
    padding: 10px;
    background-color: #4c00b4;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
}

.submit:hover {
    background-color: #3c009d;
}

/* 페이지 타이틀 및 주의사항 스타일 */
.page-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-left: 20px; /* 들여쓰기 적용 */
}

.notice {
    background-color: #ffebcc;
    padding: 10px;
    border-left: 5px solid #ffa500;
    margin-bottom: 20px;
    font-size: 14px;
    color: #333;
    border-radius: 4px;
    margin: 5px 20px; /* 들여쓰기 적용 */
    position: relative; /* 아이콘 위치를 조정하기 위해 relative로 설정 */
}

.notice_text {
    color: #DB4455;
}

.copy-icon {
    position: absolute; /* 아이콘을 고정된 위치에 배치 */
    top: 50%; /* 아이콘이 박스의 중앙에 위치하도록 설정 */
    right: 10px; /* 오른쪽 여백 */
    transform: translateY(-50%); /* 수직 중앙 정렬 */
    cursor: pointer;
    width: 24px;
    height: 24px;
}


/* 반응형 디자인 */
@media (max-width: 768px) {
    .paste-icon, .copy-icon {
        position: static;
        margin-left: 10px;
        transform: none;
    }

    .input {
        width: calc(100% - 20px); /* 작은 화면에서는 더 작은 여백 적용 */
    }

    .notice {
        padding-right: 40px; /* 작은 화면에서 추가 여백 */
    }
}

/* 트레이드 타입 셀렉트 스타일 */
.trade-type-select select {
    padding: 10px; /* 내부 여백 */
    box-sizing: border-box;
    width: 100%; /* 전체 너비 사용 */
    border: none; /* 테두리 제거 */
    background-color: transparent; /* 배경색을 투명하게 설정 */
    font-size: 19px
}

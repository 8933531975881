.order-detail-container {
  max-width: 1024px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  max-height: 85vh;
  overflow-y: auto;
}

.order-detail-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
}

.order-detail-title {
  font-size: 30px;
  color: #4c00b4;
  font-weight: bold;
  text-align: center;
  flex-grow: 1;
}

.back-icon {
  position: absolute;
  left: 0;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.back-icon:hover {
  color: #0056b3;
}

.order-detail-card {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
}

.card-title {
  font-size: 20px;
  margin: 0 0 10px;
  color: #333;
  border-bottom: 2px solid #007bff;
  padding-bottom: 5px;
}

.order-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.order-info-row {
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.order-info-row.full-width {
  grid-column: 1 / -1; /* 전체 너비 사용 */
}

.order-info-row.full-width span:last-child {
  word-break: break-all; /* 긴 텍스트 줄바꿈 */
}

.order-info-row span:first-child {
  font-weight: 600; /* 기존보다 더 두껍게 */
  color: #333;
}

/* 특정 라벨에 대해 더 두껍게 설정 */
.order-info-row.bold-label span:first-child {
  font-weight: 700; /* 더 두껍게 */
}

@media (max-width: 768px) {
  .order-detail-container {
    margin: 20px auto;
    padding: 15px;
  }

  .order-detail-header {
    align-items: flex-start;
  }

  .order-detail-title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 10px;
  }

  .back-icon {
    font-size: 20px;
  }

  .order-detail-card {
    padding: 10px;
  }

  .order-info {
    grid-template-columns: 1fr;
    gap: 5px;
  }

  .order-info-row,
  .order-info-row.full-width {
    grid-column: auto; /* 모바일에서는 모든 행이 전체 너비 사용 */
  }

  .order-info-row {
    padding: 8px;
    font-size: 14px;
  }

  .card-title {
    font-size: 18px;
    margin: 15px 0 5px;
    padding-bottom: 3px;
  }
}
